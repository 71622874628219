import { HashLink as Link } from 'react-router-hash-link';
import './App.css';
import profile from './images/profile.png'
import Startups from './startups.jsx'
import Media from './media.jsx'
import InnovationWrap from './innovation-wrap.jsx'
import Other from './other.jsx'
import Numbers from './Numbers.jsx'
import {useLocation} from "react-router-dom";

const App = () => {
    const query_search = window.location.search;

    let showMedia = false;
    if (query_search && query_search.includes('media'))
        showMedia = true;

    return (

        <div>
            <div id="heading">
                <img className="profile" src={profile} alt="Thomas Rice"/>
                <h1>Thomas Rice</h1>
            </div>

            <div id="content">
                <p>I'm a fund manager based in Sydney, Australia. I recently co-founded&nbsp;<a href="https://www.minotaurcapital.com/" target="_blank">Minotaur Capital</a>, a technology-first,
                    software-driven fundamental investment firm. We plan to launch a global long/short equities fund in the next few months.
                    If interested, please sign up on the Minotaur Capital website for updates.</p>
                <p>Read about the new firm in the AFR: <a href="https://www.afr.com/street-talk/tom-rice-and-arms-rosenberg-resurface-with-ai-powered-hedge-fund-20231211-p5eqjc" target="_blank">Tom Rice and ‘Arms’ Rosenberg resurface with AI-powered hedge fund</a></p>
                <br/>
                <a className="button" href="#innovation">Wrap</a>
                <a className="button" href="#startups">Startups</a>
                {showMedia ? <a className="button" href="#media">Media</a> : ''}
                <a className="button" href="#other">Other</a>
                <a className="button" href="#contact">Contact</a>
                <br/>

                <InnovationWrap/>

                <Startups/>

                {showMedia ? <Media/> : ''}

                <Other/>
                <Numbers/>
                <h2 id="contact">Contact Me</h2>
                <div id="contacticons">
                    <p><a className="twitter icon" target="_blank" rel="noreferrer" href="https://twitter.com/thomasrice_au"></a>
                        <a className="linkedin icon" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/thomasrice/"></a>
                        <a className="email icon" target="_blank" rel="noreferrer" href="mailto:thomas@thomasrice.com"></a>
                    </p>
                </div>
                <br/>
            </div>
            <div id="end">
                <hr/>
                <p>Thanks for visiting,</p>
                <p>Thomas</p>

            </div>
            <div id="footer">

            </div>

        </div>

    );
}

export default App;
